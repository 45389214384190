
import page from '~/mixins/page'
import pageTransition from '~/mixins/pageTransition'

export default {
  mixins: [page, pageTransition],

  provide() {
    return {
      pageId: this.page.id,
    }
  },
}
