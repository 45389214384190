export default {
  transition: {
    css: false,
    mode: 'out-in',

    // enter(el, done) {
    //   console.log('enter')

    //   done()
    // },

    leave(el, done) {
      this.$gsap
        .timeline({
          onComplete: () => {
            done()
          },
        })
        .to(el, {
          duration: 0.15,
          opacity: 0,
        })
    },
  },
}
