
import throttle from 'lodash/throttle'

export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      navDropdownisOpen: false,
    }
  },

  head() {
    if (
      !this.page.chat_active ||
      (!this.page.embed_code && !this.chatIntegrationSrc)
    ) {
      return
    }

    return {
      script: [
        {
          src: this.chatIntegrationSrc,
          id: 'ze-snippet',
          async: true,
        },
      ],
    }
  },

  computed: {
    chatIntegrationSrc() {
      const regex = /src="([^"]+)"/
      let match = null

      if (this.page.embed_code) {
        match = this.page.embed_code.match(regex)
        return match ? match[1] : null
      }

      const embedCode = this.$store.getters['globals/chatIntegration']
      if (!embedCode?.embed_code) {
        return null
      }

      match = embedCode.embed_code.match(regex)
      return match ? match[1] : null
    },
  },

  mounted() {
    this.$nuxt.$on('nav-drodown-opened', (isOpen) => {
      this.navDropdownisOpen = isOpen
    })

    this.$nuxt.$on(
      'nav-drodown-closed',
      throttle((isOpen) => {
        this.navDropdownisOpen = isOpen
      }),
      100
    )
  },
}
