import { getPageByUri } from '~/graphql/queries.js'

export default {
  async asyncData({ $graphql, route, app, error, store, $axios, redirect }) {
    const localeRegex = new RegExp(
      `/(${app.i18n.locales.map((l) => l.code).join('|')})(?=/|$)`
    )

    let uri = route.path.replace(localeRegex, '')
    uri = uri === '' ? '/' : uri

    const lang = app.i18n.locale

    try {
      let res = await $graphql.cms.request(getPageByUri, {
        uri,
        lang,
        status: 'published',
      })

      if (
        res.entry === null &&
        Object.prototype.hasOwnProperty.call(route.query, 'live-preview')
      ) {
        // If we are in live-preview mode and the page is not found, try to get the draft version
        res = await $graphql.cms.request(getPageByUri, {
          uri,
          lang,
          status: 'draft',
        })
      }

      // when no page has been found -> try to redirect
      if (res.entry === null || res.entry?.blueprint === 'level_1') {
        try {
          const { data } = await $axios.$get(`api/redirects${route.path}`)
          return redirect(data.type, data.destination)
        } catch (redirectError) {
          // show nuxt error page
          if (redirectError.response?.status === 200) {
            return error({ statusCode: 404 })
          } else {
            return error({ statusCode: redirectError.response?.status })
          }
        }
      }

      const languageSlugs = res.entry.alternates.map((alternate) => {
        return [
          alternate.language,
          {
            // pathMatch should be without lang-code and leading slash
            // Regex: Remove leading '/lang-code/' or '/' from URL.
            pathMatch: alternate.url.replace(/^\/(?:[a-z]{2}\/)?/, ''),
          },
        ]
      })

      await Promise.all([
        store.dispatch(
          'i18n/setRouteParams',
          Object.fromEntries(languageSlugs)
        ),
        store.dispatch('seo/checkIfhasAlternates', res.entry.alternates),
      ])

      return { res }
    } catch (e) {
      console.error('page/asyncData', e)

      return error({ statusCode: e.response?.status })
    }
  },

  head() {
    return {
      htmlAttrs: { lang: this.$i18n.locale },
      title: this.pageTitle,
      meta: this.generateMetaTags(),
      link: this.generateLinks(),
    }
  },

  computed: {
    page() {
      return this.res.entry
    },

    pageTitle() {
      const title = this.$removeHtmlTags(
        this.page.seo_title || this.getHero('title') || this.page.title
      )

      if (this.page.seo_default_title_deactivated || !this.seoTitleDefault) {
        return title
      }
      return this.decodeHtmlEntities(`${title} ${this.seoTitleDefault}`)
    },

    seoTitleDefault() {
      const title = this.$store.getters['globals/seo']?.seo_title
      return this.$removeHtmlTags(title)
    },

    pageDesc() {
      const description = this.$removeHtmlTags(
        this.page.seo_description ||
          this.getHero('text') ||
          this.page.bio ||
          this.page.intro
      )

      return this.decodeHtmlEntities(description)
    },

    pageImage() {
      if (this.page.seo_image) {
        return {
          url: this.page.seo_image?.permalink,
          alt: this.page.seo_image?.alt || '',
        }
      }

      if (this.page.image) {
        return {
          url: this.page.image?.permalink,
          alt: this.page.image?.alt || '',
        }
      }

      if (!this.page.heros) return null

      if (this.page?.heros?.length === 0) {
        return null
      }

      return {
        url: this.page.heros[0].image?.permalink,
        alt: this.page.heros[0].image?.alt || '',
      }
    },
  },

  methods: {
    getHero(property) {
      if (!this.page.heros) return null

      if (this.page?.heros?.length === 0) {
        return null
      }

      if (this.page?.heros[0].type === 'hero') {
        return this.$removePTags(this.page?.heros[0]?.subtitle)
      }

      return this.$removePTags(this.page?.heros[0][property])
    },

    generateMetaTags() {
      const meta = [
        {
          hid: 'description',
          name: 'description',
          content: this.pageDesc,
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.pageTitle,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.pageDesc,
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.page.url,
        },
      ]

      if (this.page.seo_hidden) {
        meta.push({ hid: 'robots', name: 'robots', content: 'noindex' })
      }

      if (this.pageImage?.url) {
        meta.push({
          hid: 'og:image',
          property: 'og:image',
          content: this.pageImage.url,
        })
      }

      if (this.pageImage?.alt) {
        meta.push({
          hid: 'og:image:alt',
          property: 'og:image:alt',
          content: this.pageImage.alt,
        })
      }

      return meta.filter((meta) => {
        return meta.content
      })
    },

    generateLinks() {
      const rootPageUrl = this.res.entry.alternates.find(
        (item) => item.is_root
      )?.permalink

      const pageAlternates = Array.isArray(this.res.entry.alternates)
        ? this.res.entry.alternates ?? []
        : []

      const alternates = pageAlternates.map((page) => {
        return {
          rel: 'alternate',
          hreflang: page.language + '-ch',
          href: page.permalink,
        }
      })

      // add x-default when there are more than one alternate
      if (rootPageUrl && alternates.length > 1) {
        // sort alternates by current set locale
        alternates.sort((a, b) =>
          a.hreflang.includes(this.$i18n.locale) ? -1 : 1
        )

        alternates.push({
          rel: 'alternate',
          hreflang: 'x-default',
          href: rootPageUrl,
        })
      }

      return [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: rootPageUrl,
        },
        ...(alternates.length > 1 ? alternates : []),
      ]
    },

    removeTrailingSlash(str) {
      return str.endsWith('/') ? str.slice(0, -1) : str
    },

    // lodash's _.unescape function decodes only a few common HTML entities.
    // If our string contains other entities (like &apos;), they remain encoded.
    // Therefore, we're using a custom function that can be extended to decode more entities.
    decodeHtmlEntities(str) {
      if (!str) return str
      const htmlEntitiesMap = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#039;': "'",
        // add more entities here as needed
      }

      return str.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, function (entity) {
        return htmlEntitiesMap[entity]
      })
    },
  },
}
